import React from 'react';
import "../App.css"
//import Slogan from "./Slogan";

const Slogan = () =>{
    return (
        <h1>aaa</h1>
    );
};


const Header = () => {
    return (
        <div className="levan" >
           Hello zaza
            <Slogan />
        </div>
    );
};

export default Header;